import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from "react";
import classnames from "classnames";
import Tooltip from "@valraiso-esf/esf-ui/es/tooltip";
import Popover from "@valraiso-esf/esf-ui/es/popover";
import ResponsiveSwitch from "./responsive-switch";
import css from "./adaptive-screen.module.css";
export default function AdaptiveScreen({ title, header, className, classNames, children, }) {
    const classesMain = classnames(className, classNames?.main, css.inner);
    const classesTitle = classnames(classNames?.title, css.title);
    const isStrTitle = title && typeof title === "string";
    const isJsxTitle = title && typeof title !== "string";
    checkChildren(children);
    return (_jsx(Popover.Zone, { children: _jsx(Tooltip.Zone, { children: _jsxs("div", { className: classesMain, children: [header, isJsxTitle && _jsx("h1", { className: classesTitle, children: title }), isStrTitle && (_jsx("h1", { className: classesTitle, dangerouslySetInnerHTML: { __html: title } })), _jsx(ResponsiveSwitch, { children: children })] }) }) }));
}
AdaptiveScreen.Mobile = ResponsiveSwitch.Mobile;
AdaptiveScreen.Desktop = ResponsiveSwitch.Desktop;
AdaptiveScreen.Common = ResponsiveSwitch.Common;
function checkChildren(children) {
    const childs = React.Children.toArray(children);
    const invalidChildType = childs.some((child) => {
        const element = child;
        return (element.type !== AdaptiveScreen.Mobile &&
            element.type !== AdaptiveScreen.Desktop &&
            element.type !== AdaptiveScreen.Common);
    });
    if (invalidChildType) {
        throw new Error("Children must be of type AdaptiveScreen.Mobile or AdaptiveScreen.Desktop or AdaptiveScreen.Common");
    }
}
