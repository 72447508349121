import * as React from "react";
import * as Query from "react-query";
import * as Auth from "oauth2";
import * as App from "global-state";
import * as Api from "../../services/api";
import Menu from "@valraiso-esf/esf-ui/es/menu";
import Toast from "@valraiso-esf/esf-ui/es/toast";
import HomeIcon from "@valraiso-esf/esf-icons/es/home";
import AcUnit from "@valraiso-esf/esf-icons/es/ac-unit";
import CheckRoomIcon from "@valraiso-esf/esf-icons/es/checkroom";
import StarIcon from "@valraiso-esf/esf-icons/es/star";
import CampaignIcon from "@valraiso-esf/esf-icons/es/campaign";
import FlagIcon from "@valraiso-esf/esf-icons/es/flag";
import ChevronRightIcon from "@valraiso-esf/esf-icons/es/chevron-right";
import BookIcon from "@valraiso-esf/esf-icons/es/book";
import BoltIcon from "@valraiso-esf/esf-icons/es/bolt";
import HistoryEduIcon from "@valraiso-esf/esf-icons/es/history-edu";
import EuroSymbolIcon from "@valraiso-esf/esf-icons/es/euro-symbol";
import SettingsIcon from "@valraiso-esf/esf-icons/es/settings";
import FolderIcon from "@valraiso-esf/esf-icons/es/folder";
import PermMediaIcon from "@valraiso-esf/esf-icons/es/perm-media";
import BookOnline from "@valraiso-esf/esf-icons/es/book-online";
import Landscape from "@valraiso-esf/esf-icons/es/landscape";
import SchoolHat from "@valraiso-esf/esf-icons/es/school-hat";
import BookmarkIcon from "@valraiso-esf/esf-icons/es/bookmark";
import DescriptionIcon from "@valraiso-esf/esf-icons/es/description";
import NestEcoLeafIcon from "@valraiso-esf/esf-icons/es/nest-eco-leaf";
import CalendarMonthIcon from "@valraiso-esf/esf-icons/es/calendar-month";
import NewsMode from "@valraiso-esf/esf-icons/es/news-mode";
import CampainIcon from "@valraiso-esf/esf-icons/es/campaign";
import WorkIcon from "@valraiso-esf/esf-icons/es/work";
import PhotoLibrary from "@valraiso-esf/esf-icons/es/photo-library";
import HowToVote from "@valraiso-esf/esf-icons/es/how-to-vote";
import { link } from "common/es/link";

import css from "./navigation.module.css";

type Icon = (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
type MenuProps = {
  navigation: Navigation;
  onClick: any;
  small?: boolean;
  withIcons?: boolean;
  icon?: Icon;
  ejectToast?: (toast: boolean) => void;
};

const icons: Record<string, Icon> = {
  "/annuaire-esf": BookIcon,
  "/bonnes-adresses": BookmarkIcon,
  "/mon-planning": CalendarMonthIcon,
};

type Props = {
  toggleDrawer: (e: React.MouseEvent) => void;
};

export default function NavigationMenu({ toggleDrawer }: Props) {
  const [state] = App.useGlobalState();
  const { user, token } = Auth.useAuth();
  const [toast, setToast] = React.useState<boolean | undefined>(undefined);
  const ejectToast = Toast.eject(setToast);

  const ecole = state.ecole?.code;
  const { data } = Query.useQuery(
    ["navigation-menu", state.ecole?.code, user],
    () => Api.fetchMenu(ecole)
  );

  return (
    <>
      {data?.navigation && (
        <div className={css.menu}>
          {renderMenu(user, ecole, token, {
            navigation: data.navigation,
            onClick: toggleDrawer,
            icon: ChevronRightIcon,
            withIcons: true,
            ejectToast: ejectToast,
          })}
        </div>
      )}
      {data?.shortcuts && (
        <>
          <div className={css.divider} />
          <div className={css.menu}>
            {renderMenu(user, ecole, token, {
              navigation: data.shortcuts,
              onClick: toggleDrawer,
              small: true,
              ejectToast: ejectToast,
            })}
          </div>
        </>
      )}
      {toast && (
        <Toast seconds={2.5} variant="warning">
          Service momentanément indisponible
        </Toast>
      )}
    </>
  );
}

function renderMenu(
  user: Auth.User | undefined,
  ecole: number | undefined,
  token: string | undefined,
  props: MenuProps
) {
  const { navigation, onClick, icon, small, withIcons, ejectToast } = props;
  const variant = small ? "small" : undefined;
  return (
    <Menu variant={variant} link={link}>
      {navigation.map((item, i) => {
        const path = getPath(user, ecole, token, item);
        const [opened, active] = getItemState(path, item.children);
        return (
          <Menu.Item
            key={i}
            title={item.label}
            to={path}
            external={item.external}
            icon={withIcons ? getIcon(item, icon) : undefined}
            onClick={onClick}
            opened={opened}
            active={opened || active}
            disabled={item.disabled}
            onClickDisabled={() => ejectToast?.(true)}
          >
            {item.children &&
              renderMenu(user, ecole, token, {
                navigation: item.children,
                onClick,
                ejectToast,
              })}
          </Menu.Item>
        );
      })}
    </Menu>
  );
}

function getPath(
  user: Auth.User | undefined,
  ecole: number | undefined,
  token: string | undefined,
  item: NavigationNode
) {
  if (item.path === undefined) {
    return undefined;
  }

  const share = item.share ?? [];

  const search = new URLSearchParams();
  if (user && share.includes("instructor_code")) {
    search.append("instructor", user.login);
  }

  if (ecole && share.includes("school_code")) {
    search.append("school", String(ecole));
  }

  if (token && share.includes("token")) {
    search.append("token", token);
  }

  const params = search.toString();
  if (params.length === 0) {
    return item.path;
  }

  const sep = item.path.replace("/ressource-web?", "").includes("?")
    ? "&"
    : "?";
  return (
    item.path +
    (item.path.includes("/ressource-web?")
      ? encodeURIComponent(sep + params)
      : sep + params)
  );
}

function getIcon(item: NavigationNode, defaultIcon: Icon | undefined) {
  const path = item.path;
  if (item.label === "Mon école") return StarIcon;
  if (item.label === "Espace syndical") return FlagIcon;
  if (
    item.label.toLowerCase().includes("actualités") ||
    item.label.toLowerCase().includes("annonces")
  )
    return CampaignIcon;
  if (item.label === "Médias") return PermMediaIcon;
  if (item.label === "Documents") return FolderIcon;
  if (item.label === "Agenda") return CalendarMonthIcon;
  if (item.label === "Administration") return SettingsIcon;
  if (
    item.label === "Proshop" ||
    item.label === "Proshop école" ||
    item.label === "Proshop moniteur"
  )
    return CheckRoomIcon;
  if (item.label === "Mémento") return HistoryEduIcon;
  if (item.label === "Comptes école") return EuroSymbolIcon;
  if (item.label === "Fiche suivi école") return DescriptionIcon;
  if (item.label === "Déclaration d'accidents") return BoltIcon;
  if (item.label === "Traçabilité") return Landscape;
  if (item.label === "esf Academy") return SchoolHat;
  if (item.label.includes("Météo")) return AcUnit;
  if (item.label.includes("Réservation")) return BookOnline;
  if (item.label === "Montagne responsable") return NestEcoLeafIcon;
  if (item.label === "Journées professionnelles") return WorkIcon;
  if (item.label === "Petites annonces") return CampainIcon;
  if (item.label === "Photohèque") return PhotoLibrary;
  if (item.label === "AG et réunions") return HowToVote;
  if (item.label === "Traces") return NewsMode;
  if (!path) return defaultIcon;
  if (path === "/") return HomeIcon;
  const matches = Object.entries(icons).filter(([k]) => path.startsWith(k));
  const icon = matches.reduce((acc: [string, Icon] | undefined, match) => {
    if (!acc) return match;
    if (match[0].length > acc[0].length) return match;
    return acc;
  }, undefined);

  return icon ? icon[1] : defaultIcon;
}

function getItemState(
  path?: string,
  children?: NavigationNode[]
): [boolean, boolean] {
  const isRoot = path === "/";
  const current = window.location.pathname;
  const childActive =
    children !== undefined &&
    children?.some(
      (child) => child.path !== undefined && current.startsWith(child.path)
    );

  const opened = isRoot
    ? false
    : (path !== undefined && current.startsWith(path)) || childActive;
  const active = isRoot
    ? current === path
    : (path !== undefined && current.startsWith(path)) || current === path;

  return [opened, active];
}
