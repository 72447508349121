import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from "react";
import classNames from "classnames";
import css from "./avatar.module.css";
export default function Avatar({ initials, imageUrl, size = 48, className, ...props }) {
    const [ready, setReady] = React.useState(false);
    const ref = React.useRef(null);
    const loaded = () => setReady(true);
    const errored = () => setReady(false);
    React.useEffect(() => {
        const img = ref.current;
        if (img && imageUrl) {
            img.crossOrigin = "anonymous";
            img.src = imageUrl;
            img.addEventListener("load", loaded);
            img.addEventListener("error", errored);
        }
        return () => {
            if (img) {
                img.removeEventListener("load", loaded);
                img.removeEventListener("error", errored);
            }
        };
    }, [imageUrl]);
    const avatarClasses = classNames(className, css.avatar, {
        [css.loaded]: ready,
    });
    const style = {
        width: size,
        height: size,
        fontSize: Math.round(size * 0.34),
    };
    return (_jsxs("div", { ...props, className: avatarClasses, style: style, children: [imageUrl && _jsx("img", { ref: ref, alt: initials }), initials] }));
}
