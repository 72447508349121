import * as React from "react";
import * as Query from "react-query";
import * as Auth from "oauth2";
import NotificationState from "notification-state";
import VoteState from "vote-state";
import Loader from "@valraiso-esf/esf-ui/es/loader-bar";
import EsfIcon from "@valraiso-esf/esf-icons/es/esf";
import SnmsfIcon from "@valraiso-esf/esf-icons/es/snmsf";
import Navigation from "./navigation";
import ErrorBoundary from "./error-boundary";
import css from "./layout.module.css";
import Toast from "@valraiso-esf/esf-ui/es/toast";

type Props = {
  children: React.ReactNode;
};

export default function Layout({ children }: Props) {
  const isFetching = Query.useIsFetching();
  const loading = Boolean(isFetching);
  return (
    <Toast.Zone>
      <div className={css.container}>
        <EsfIcon className={css.esfLogo} />
        {loading && (
          <div className={css.loader}>
            <Loader />
          </div>
        )}
        <ErrorBoundary>
          <div className={css.screen}>
            <Auth.Provider>
              <NotificationState>
                <VoteState>
                  <Navigation />
                </VoteState>
                <ErrorBoundary>{children}</ErrorBoundary>
              </NotificationState>
            </Auth.Provider>
          </div>
          <footer className={css.footer}>
            <span>
              Un service du <b>SNMSF</b>
            </span>
            <SnmsfIcon width={30} height={30} className={css.snmsfLogo} />
          </footer>
        </ErrorBoundary>
      </div>
    </Toast.Zone>
  );
}
