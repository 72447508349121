import * as Config from "configuration";
import * as Auth from "oauth2";
const origin = Config.get("apiReunions");
export async function renvoiVotes(reponsesNonVotees) {
    const url = `${origin}/api/reunions/motions/renvoi-votes`;
    return Auth.fetch(url, {
        method: "POST",
        headers: {
            "content-type": "application/json; charset=utf-8",
        },
        body: JSON.stringify({ reponses: reponsesNonVotees }),
    });
}
