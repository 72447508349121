import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from "classnames";
import Popover from "@valraiso-esf/esf-ui/es/popover";
import Tooltip from "@valraiso-esf/esf-ui/es/tooltip";
import css from "./screen.module.css";
export default function Screen({ title, header, className, children, fullscreen, }) {
    const classes = classNames(className, css.inner, {
        [css.fullscreen]: fullscreen,
    });
    const isStrTitle = title && typeof title === "string";
    const isJsxTitle = title && typeof title !== "string";
    return (_jsx(Popover.Zone, { children: _jsx(Tooltip.Zone, { children: _jsxs("div", { className: classes, children: [fullscreen && _jsx("div", { className: css.headerFullscreen, children: header }), !fullscreen && header, isJsxTitle && _jsx("h1", { className: css.title, children: title }), isStrTitle && (_jsx("h1", { className: css.title, dangerouslySetInnerHTML: { __html: title } })), children] }) }) }));
}
