import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from "react";
import * as Auth from "oauth2";
import * as Router from "react-router-dom";
import * as Api from "./api";
import Toast from "@valraiso-esf/esf-ui/es/toast";
import Info from "@valraiso-esf/esf-icons/es/info";
const STORED_REPONSES_KEY = "reunions-reunion-live-reponses-";
const context = React.createContext({ pendingReponses: false });
export function useNotificationState() {
    return React.useContext(context);
}
export default function VoteStateProvider({ children }) {
    const [toast, setToast] = React.useState();
    const ejectToast = React.useMemo(() => Toast.eject(setToast), []);
    const { signed, user } = Auth.useAuth();
    const location = Router.useLocation();
    const intervalRef = React.useRef(undefined);
    const errorToastDisplayed = React.useRef(false);
    const [pendingReponses, setPendingReponses] = React.useState(false);
    const _clearInterval = () => {
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
            intervalRef.current = undefined;
        }
    };
    const renvoiVotes = React.useCallback(async (user) => {
        try {
            const reponses = findReponsesNonEnvoyees(user);
            if (reponses.length > 0) {
                try {
                    const result = await Api.renvoiVotes(reponses);
                    if (result) {
                        cleanupLocalStorage(user);
                        if (result.length > 0) {
                            storeReponses(user, result);
                        }
                        else {
                            setPendingReponses(false);
                            ejectToast({
                                message: "Les votes ont été transmis.",
                                success: true,
                            });
                            _clearInterval();
                        }
                    }
                }
                catch (error) {
                    console.error(error);
                }
            }
            else {
                setPendingReponses(false);
                _clearInterval();
            }
        }
        catch (error) {
            console.error(error);
        }
    }, [location.pathname]);
    React.useEffect(() => {
        if (signed &&
            user &&
            !location.pathname.startsWith("/reunions/session-en-cours/")) {
            const reponses = findReponsesNonEnvoyees(user);
            if (reponses.length > 0) {
                if (!errorToastDisplayed.current) {
                    errorToastDisplayed.current = true;
                    requestAnimationFrame(() => {
                        ejectToast({
                            message: "Des votes n'ont pu être transmis, retrouver du réseau pour les transmettre.",
                            success: false,
                        });
                    });
                }
                if (reponses.length > 0 && !intervalRef.current) {
                    intervalRef.current = setInterval(() => {
                        renvoiVotes(user);
                    }, 10000);
                }
            }
        }
        else {
            _clearInterval();
        }
        return () => {
            _clearInterval();
        };
    }, [signed, location.pathname, user?.login]);
    return (_jsxs(context.Provider, { value: { pendingReponses }, children: [children, toast && (_jsx(Toast, { variant: toast.success ? "success" : "error", permanent: true, left: _jsx(Info, {}), onClose: () => (errorToastDisplayed.current = false), children: toast.message }))] }));
}
function findReponsesNonEnvoyees(user) {
    const reunionReponsesNonEnvoyees = Object.keys(localStorage).filter((key) => key.startsWith(STORED_REPONSES_KEY));
    return reunionReponsesNonEnvoyees.reduce((acc, key) => {
        const userIdAndReunionId = key.replace(STORED_REPONSES_KEY, "");
        const [reunionId, userLogin] = userIdAndReunionId.split("-");
        if (userLogin === user.login) {
            const storedReponse = localStorage.getItem(key);
            if (storedReponse && reunionId) {
                const parsedStoredReponse = JSON.parse(storedReponse);
                acc.push(...parsedStoredReponse.map((r) => ({ ...r, reunionId })));
            }
        }
        return acc;
    }, []);
}
function cleanupLocalStorage(user, keyToRemove) {
    const reunionReponsesNonEnvoyees = Object.keys(localStorage).filter((key) => key.startsWith(STORED_REPONSES_KEY));
    reunionReponsesNonEnvoyees.forEach((key) => {
        const shouldRemove = keyToRemove ? keyToRemove.includes(key) : true;
        if (shouldRemove) {
            const userIdAndReunionId = key.replace(STORED_REPONSES_KEY, "");
            const [_, userLogin] = userIdAndReunionId.split("-");
            if (userLogin === user.login) {
                localStorage.removeItem(key);
            }
        }
    });
}
function storeReponses(user, reponses) {
    const groupedResults = reponses.reduce((acc, r) => {
        const { reunionId, ...rest } = r;
        if (!acc[reunionId]) {
            acc[reunionId] = [];
        }
        acc[reunionId].push(rest);
        return acc;
    }, {});
    // stockage des votes qui n'ont pu être enregistrés
    Object.entries(groupedResults).forEach(([reunionId, reponses]) => {
        localStorage.setItem(`${STORED_REPONSES_KEY}${reunionId}-${user?.login}`, JSON.stringify(reponses));
    });
}
